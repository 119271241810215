body {
	font-family: 'Source Sans Pro', sans-serif;
}

.center-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

.input-container {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
}
  
input[type="file"] {
	position: absolute;
	z-index: -1;
	top: 10px;
	left: 8px;
	font-size: 17px;
	color: #b8b8b8;
}
  
.button-wrap {
	position: relative;
}
  
.button {
	display: inline-block;
	padding: 12px 18px;
	cursor: pointer;
	border-radius: 5px;
	background-color: #007bff;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
}

.caption {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.react-pdf__Page__canvas {
	margin-left: auto;
	margin-right: auto;
	min-width: 100%;
	/* height: 100% !important; */
	padding: 20px;
}

#pdf-download {
	visibility: hidden;
}

.react-pdf__Page__textContent:before, .viewerWrapper::before {
	content: '';
	background-image: url('images/logo-orig2.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position-x: center;
	background-position-y: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	opacity: 0.3;
}

.rpv-toolbar__right {
	display: none;
}

.rpv-toolbar__center {
	justify-content: flex-end;
}